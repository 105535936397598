.AmbientVideo {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.PresentationButton {
    position: absolute;
    bottom: 100px;
    left: 45%;
    width: 166px;
    height: 81px;
}

.VideoBox {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
   /* animation-name: video;
    animation-duration: 750ms;*/
  }
  
  .VideoInnerBox {
    position: relative;
    top: 10px;
    width: 400px;
    height: 60px;
    margin: auto;
  }

  .VideoInnerBox .VerticalAlignButton {
    position: absolute;
    top: 0;
    right: 10px;
    width: 40px;
    height: 40px;
    color: white;
    border: none;
    font-size: larger;
  }
  
  @keyframes video {
    from {top: -1000px;}
    to {top: 10px;}
  }

.FormContainer {
  position: relative;
  bottom: 60px;
  right: 20px;
  height: auto;
}

.FindModeButton {
  position: relative;
  top: 10px;
  float: right;
  width: 100px;
  height: 27px;
}

.Form {
  position: relative;
  top: 0px;
  width: 200px;
  height: auto;
  background-color: #414141;
  padding: 10px;
}

.Form label {
  text-align: left;
  margin: 10px;
}

.Form button {
  margin: 20px 0px;
  width: 100%;
}

.Date {
  font-family: 'Ethnocentric Rg';
  color: rgb(162, 248, 255);
  font-size: 1vw;
}