.Start {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;;
}

.Start button {
  cursor: pointer;
}
